<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '830px' }"
    :header="'Imagenes Paciente : ' + (detalle.nombre ?? 'cargando ...')"
    :modal="true"
    :maximizable="true"
    class="p-fluid"
    @hide="cerrarModal"
    :loading="loading"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div v-for="imagen in imagenes" :key="imagen.id">
          <div class="field col-12 md:col-12">
            <div class="p-imputgroup">
              <Image
                :src="'data:image/png;base64, ' + imagen.image"
                width="200"
                height="200"
                alt="imagen"
                preview
              >
              </Image>
              <Button
                label="Eliminar"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-sm"
                @click="eliminarImagen(imagen.id)"
                v-tooltip.top="'Quitar Imagen'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex md:justify-content-end">
        <FileUpload
          mode="basic"
          name="demo[]"
          accept="image/*"
          :maxFileSize="1000000"
          :customUpload="true"
          chooseLabel="CARGAR NUEVA IMAGEN"
          @uploader="onUpload"
          :auto="true"
          class="p-button-danger p-button-lg p-button-outlined p-button-rounded mr-2"
        />
        <Button
          label="CERRAR"
          icon="pi pi-times"
          class="p-button-secondary p-button-lg p-button-outlined p-button-rounded"
          @click="cerrarModal"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
  },
  clienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      producto: this.cliente_id,
      detalle: [],
      imagenes: [],
      loading: true,
    };
  },
  created() {
    this.clienteService = new ClienteService();
  },
  methods: {
    eliminarImagen(imagen_id) {
      this.clienteService.deleteImage(imagen_id).then(() => {
        this.buscarCliente();
        this.$toast.add({
          severity: "success",
          summary: "Actualizado",
          detail: "Imagen eliminada correctamente",
          life: 3000,
        });
      });
    },
    cerrarModal() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.$emit("actualizarListado");
    },
    buscarCliente() {
      this.clienteService.getClienteImages(this.cliente_id).then((data) => {
        this.detalle = data.cliente;
        this.imagenes = data.images;
        this.loading = false;
      });
    },
    onUpload(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("imagen", archivo);
      formData.append("cliente_id", this.cliente_id);

      this.clienteService
        .uploadImage(formData)
        .then(() => {
          this.buscarCliente();
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Imagen cargada correctamente",
            life: 3000,
          });
          this.$emit("actualizarListado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    show: function (val) {
      this.mostrarModal = val;
    },
    cliente_id: function (val) {
      this.cliente = val;
      this.buscarCliente();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-imputgroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 10px;

  img {
    border-radius: 10px;
  }

  button {
    margin-top: 10px;
  }
}
</style>
